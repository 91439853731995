"use client";

import Image from "next/image";

import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { slideInFromRight, slideInFromLeft } from "@/utils/motion";

export default function Mission() {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  return (
    <section className="w-full h-full bg-gradient-to-r from-primary to-secondary text-center">
      <motion.div
        ref={ref}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        transition={{ delay: 1, duration: 1, type: "spring", stiffness: 20 }}
        className="w-full px-4 py-8 flex flex-col items-center"
      >
        <h2
            className={
              "max-w-2xl mb-4 text-4xl tracking-wide leading-none text-primaryText md:text-5xl xl:text-6xl ivymode-thin"
            }
          >
            Our Mission
          </h2>
          <p className="max-w-2xl mb-6 font-light text-secondaryText lg:mb-8 md:text-lg lg:text-xl">
            Lattes & Littles is a mom group dedicated to fostering community and
            connection among mothers by providing a welcoming space to gather,
            share experiences, and enjoy coffee together.
          </p>
      </motion.div>
    </section>
  );
}
