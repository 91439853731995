'use client'

import Sponsor from "./animated-sponsor";

const sponsors = [
  {
    href: "https://www.chick-fil-a.com",
    src: "/chick.svg",
  },
  {
    href: "https://drinklmnt.com/",
    src: "/lmnt.svg",
  },
  {
    href: "https://shop.sourstrips.com/",
    src: "/sourstrips.svg",
  },
  {
    href: "https://lovecorn.com/",
    src: "/lovecornlogo.svg",
  },
  {
    href: "https://tubbytodd.com/",
    src: "/tubbytoddlogo.svg",
  },
  {
    href: "https://myserenitykids.com/",
    src: "/serenitykidslogo.svg",
  },
];

export default function Sponsors() {
  return (
    <div className="px-4 mx-auto m-10 md:m-20 text-center  lg:px-36 w-full">
      <span className="font-semibold text-primaryText uppercase">
        Thank you to our latest sponsors
      </span>
      <div className="flex w-full space-x-6 items-center mt-8 sm:justify-evenly ">
        {sponsors.map((sponsor, index) => (
          <Sponsor
            key={index}
            href={sponsor.href}
            src={sponsor.src}
            index={index}
          />
        ))}
        {/* <Link href="https://www.chick-fil-a.com" target="_blank" className="mb-5 lg:mb-0">
          <Image
            src="/chick.svg"
            width={200}
            height={200}
            alt="chik-fil-a logo"
            loading="eager"
          />
        </Link>
        <Link href="https://drinklmnt.com/" target="_blank" className="mb-5 lg:mb-0">
          <Image
            src="/lmnt.svg"
            width={100}
            height={200}
            alt="lmnt logo"
            loading="eager"
          />
        </Link>
        <Link href="https://shop.sourstrips.com/" target="_blank" className="mb-5 lg:mb-0">
          <Image
            src="/sourstrips.svg"
            width={200}
            height={200}
            alt="sour strips logo"
            loading="eager"
          />
        </Link>
        <Link href="https://shop.sourstrips.com/" target="_blank" className="mb-5 lg:mb-0">
          <Image
            src="/lovecornlogo.svg"
            width={200}
            height={200}
            alt="sour strips logo"
            loading="eager"
          />
        </Link>
        <Link href="https://shop.sourstrips.com/" target="_blank" className="mb-5 lg:mb-0">
          <Image
            src="/tubbytoddlogo.svg"
            width={200}
            height={200}
            alt="sour strips logo"
            loading="eager"
          />
        </Link>
        <Link href="https://shop.sourstrips.com/" target="_blank" className="mb-5 lg:mb-0">
          <Image
            src="/serenitykidslogo.svg"
            width={200}
            height={200}
            alt="sour strips logo"
            loading="eager"
          />
        </Link> */}
      </div>
    </div>
  );
}
