"use client";

import { motion } from "framer-motion";
import { BallPit } from "./ball-pit";
import Boxes from "./boxes";

export default function Hero() {
  return (
    <section className="w-full flex items-center h-full relative">
      <video autoPlay muted loop playsInline className="video">
        <source src="/coffee2.mp4" type="video/mp4" />
      </video>
      <div className="bg-black bg-opacity-30 w-full h-full z-[10]">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, type: "spring", stiffness: 20 }}
          className="py-8 px-4 mx-auto max-w-screen-xl text-center text-primaryText lg:py-16 lg:px-12"
        >
          <a
            href="/events"
            className="inline-flex justify-between items-center py-1 px-1 pr-4 mb-7 text-sm bg-secondary rounded-full hover:bg-gray-100"
            role="alert"
            aria-label="Check our calendar to find the next event"
          >
            <span className="text-xs bg-button text-secondary rounded-full px-4 py-1.5 mr-3">
              New
            </span>{" "}
            <span className="text-sm font-medium">View our events!</span>
            <svg
              className="ml-2 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
          </a>
          <h1
            className={
              "text-4xl tracking-tight leading-none text-primary lg:text-6xl ivymode-thin"
            }
          >
            Lattes & Littles:
          </h1>
          <h2
            className={
              "text-4xl tracking-tight leading-none text-primary lg:text-4xl ivymode-thin"
            }
          >
            A Mom Community
          </h2>

          <div className="flex flex-col items-center space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4 py-8">
            <a
              href="/about"
              className="w-full md:max-w-80 inline-flex items-center justify-center px-5 py-3 text-xl font-bold text-center bg-button text-primary rounded-full"
            >
              About us
            </a>
            <a
              href="/faq"
              className="w-full md:max-w-80 inline-flex items-center justify-center px-5 py-3 text-xl font-bold text-button text-center bg-primary border-2 border-button rounded-full bg-primary-700 hover:bg-gray-100"
            >
              FAQ
            </a>
          </div>
          {/* <Sponsors /> */}
        </motion.div>
      </div>
    </section>
  );
}
