"use client";

import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Image from "next/image";
import Link from "next/link";

interface Props {
  href: string;
  src: string;
  index: number;
}

const Sponsor = ({ href, src, index }: Props) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  const imageVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const animationDelay = 0.5;

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      variants={imageVariants}
      animate={inView ? "visible" : "hidden"}
      custom={index}
      transition={{ delay: index * animationDelay }}
    >
      <Link href={href} target="_blank" className="mb-5 lg:mb-0">
        <Image src={src} width={200} height={200} alt="Sponsor Logo" loading="eager" />
      </Link>
    </motion.div>
  );
};

export default Sponsor;
