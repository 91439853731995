"use client";

import { FaInstagram, FaLocationDot } from "react-icons/fa6";
import { BsCalendar4Event } from "react-icons/bs";
import Image from "next/image";
import Link from "next/link";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

import { slideInFromLeft } from "@/utils/motion";

export default function Locations() {
  return (
    <section className="bg-secondary w-full">
      <Fresno />
      <Redlands />
    </section>
  );
}

function Fresno() {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6"
    >
      <motion.div
       variants={slideInFromLeft(0.1)}
       >
        <Image
          className="w-full shadow-lg shadow-gray-800"
          src="/fresno.webp"
          alt="dashboard image"
          height={500}
          width={500}
        />
      </motion.div>
      <motion.div
       variants={slideInFromLeft(0.2)} 
       className="mt-4 md:mt-0"
       >
        <h2
          className={
            "mb-4 text-4xl tracking-wide text-primaryText flex ivymode-thin"
          }
        >
          <FaLocationDot /> Fresno, California
        </h2>
        <p className="mb-6 font-light text-secondaryText md:text-lg">
          Lattes and Littles hosts regular meet-ups in Fresno, CA, at various
          local coffee shops and parks, providing a warm and inviting atmosphere
          for mothers to connect and share their parenting journeys. These
          gatherings offer a chance for moms to enjoy a cup of coffee, exchange
          tips, and unwind while their children engage in fun, supervised
          activities. By meeting in popular Fresno spots like Kuppa Joy Coffee
          House and Woodward Park, the group fosters a strong sense of community
          and support among local mothers
        </p>
        <div className="flex space-x-4">
          <Link
            href="https://www.instagram.com/fresnolattesandlittles"
            aria-label="Check out our Instagram"
            className="transition ease-in-out hover:-translate-y-1 duration-300 text-primaryText"
          >
            <FaInstagram size={30} />
          </Link>
          <Link
            href="/events"
            aria-label="Check out our calendar"
            className="transition ease-in-out hover:-translate-y-1 duration-300 text-primaryText"
          >
            <BsCalendar4Event size={27} />
          </Link>
        </div>
      </motion.div>
    </motion.div>
  );
}

function Redlands() {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6"
    >
      <motion.div variants={slideInFromLeft(0.1)} className="md:hidden">
        <Image
          className="w-full md:hidden shadow-lg shadow-gray-800"
          src="/redlands.webp"
          alt="dashboard image"
          height={500}
          width={500}
        />
      </motion.div>
      <motion.div variants={slideInFromLeft(0.2)} className="mt-4 md:mt-0">
        <h2
          className={
            "mb-4 text-4xl tracking-wide text-primaryText flex ivymode-thin"
          }
        >
          <FaLocationDot />
          Redlands, California
        </h2>
        <p className="mb-6 font-light text-secondaryText md:text-lg">
          Lattes and Littles organizes regular meet-ups in Redlands, CA, at
          charming local venues such as Olive Avenue Market and Prospect
          Park, offering a friendly and relaxed environment for moms to bond
          over coffee and shared experiences. These gatherings provide a space
          for mothers to socialize, share advice, and build lasting friendships
          while their little ones play and explore. By hosting events in iconic
          Redlands locations, the group creates a vibrant network of support and
          connection for mothers in the area.
        </p>
        <div className="flex space-x-4">
          <Link
            href="https://www.instagram.com/redlandslattesandlittles"
            aria-label="Check out our Instagram"
            className="transition ease-in-out hover:-translate-y-1 duration-300 text-primaryText"
          >
            <FaInstagram size={30} />
          </Link>
          <Link
            href="/events"
            aria-label="Check out our calendar"
            className="transition ease-in-out hover:-translate-y-1 duration-300 text-primaryText"
          >
            <BsCalendar4Event size={27} />
          </Link>
        </div>
      </motion.div>
      <motion.div variants={slideInFromLeft(0.3)}>
        <Image
          className="w-full hidden md:flex shadow-lg shadow-gray-800"
          src="/redlands.webp"
          alt="dashboard image"
          height={500}
          width={500}
        />
      </motion.div>
    </motion.div>
  );
}
